import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import Image from "gatsby-image"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogTag = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <SEO title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout isHomePage>
      <SEO title="All posts" />

      

      <ol style={{ listStyle: `none`, paddingInlineStart: '0px'  }}>
        {posts.map(post => {
          const title = post.title
          let featuredImage = {
            fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
            alt: post.featuredImage?.node?.alt || ``,
          }
          return (
            <li key={post.uri}>
              <article className="card card-horizontal card-hover mb-grid-gutter" itemScope
                itemType="http://schema.org/Article">
              <div className="card-img-top d-flex align-items-center justify-content-center">
                
                {featuredImage?.fluid && (
                    <Image
                      fluid={featuredImage.fluid}
                      alt={featuredImage.alt}
                      style={{ width: '100%' }}
                    />
                  )}
              </div>
              
              <div class="card-body">
                <h2 class="h4 nav-heading text-capitalize mb-3">
                <Link to={post.uri} itemProp="url">
                      <span itemProp="headline">{parse(title)}</span>
                    </Link>
                </h2>
                
                <section itemProp="description" class="mb-0 font-size-lg">{parse(post.excerpt)}</section>
                
                <div class="mt-3 text-right text-nowrap"></div>
              </div>
              </article>                                              
              
            </li>
          )
        })}
      </ol>

      {previousPagePath && (
        <>
          <Link className="btn btn-success " style={{float:'left', marginBottom: '20px'}} to={previousPagePath}>Previous page</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link className="btn btn-success " style={{float:'right', marginBottom: '20px'}} to={nextPagePath}>Next page</Link>}
    </Layout>
  )
}

export default BlogTag


export const pageQuery = graphql`
  query($slug: String!, $skip: Int!, $limit: Int!) {
    allWpPost(
     skip: $skip
     limit: $limit
     filter: { tags: { nodes: { elemMatch: { slug: { eq: $slug   } } } } }
     sort: { fields: date, order: DESC }
    ) {
        nodes {
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
            excerpt
            uri
            date(formatString: "MMMM DD, YYYY")
            title
            excerpt
          }
    }
   }      
`